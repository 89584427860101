/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter, Redirect, Route, Switch } from 'react-router-dom';

import './assets/css/argon-dashboard-react.css';
import './assets/vendor/@fortawesome/fontawesome-free/css/all.min.css';
import './assets/vendor/nucleo/css/nucleo.css';

import AdminLayout from './layouts/Admin';
import AuthLayout from './layouts/Auth';

export const globalStyles = {
  colors: {
    primary: '#153855',
    secondary: '#F5891F',
    secondaryDark: '#dd5319',
  },
};

const theme = createTheme({
  palette: {
    primary: {
      main: globalStyles.colors.primary,
    },
    secondary: {
      main: globalStyles.colors.secondary,
    },
  },
});

ReactDOM.render(
  <HashRouter>
    <ThemeProvider theme={theme}>
      <Switch>
        <Redirect exact from="/" to="/auth/login" />
        <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
        <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
        <Redirect from="/" to="/admin/index" />
      </Switch>
    </ThemeProvider>
  </HashRouter>,
  document.getElementById('root')
);
