/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import ChangePassword from './views/examples/ChangePassword';
import Fulfilled from './views/examples/Fulfilled';
import GetAccount from './views/examples/GetAccount';
import Login from './views/examples/Login.jsx';
import ForgotPassword from './views/examples/Password.jsx';

var routes = [
  // {
  //   path: "/index",
  //   name: "Dashboard",
  //   icon: "ni ni-bullet-list-67 text-blue",
  //   component: Index,
  //   layout: "/admin"
  // },
  // {
  //   path: "/sort-transactions",
  //   name: "Sort Transactions",
  //   icon: "ni ni-bullet-list-67 text-blue",
  //   component: LandingPage,
  //   layout: "/admin",
  //   role: ['admin', 'rep'],

  // },

  {
    path: '/transactions',
    name: 'Transactions',
    icon: 'ni ni-bullet-list-67 text-blue',
    component: Fulfilled,
    layout: '/admin',
    role: ['admin', 'rep'],
  },
  {
    path: '/get-account',
    name: 'Get Account',
    icon: 'ni ni-diamond text-blue',
    component: GetAccount,
    layout: '/admin',
    role: ['admin', 'rep'],
  },
  // {
  //   path: "/create-user",
  //   name: "CreateUser",
  //   icon: "ni ni-circle-08 text-primary",
  //   component: CreateUser,
  //   layout: "/admin",
  //   role: ['admin'],

  // },
  // {
  //   path: "/users",
  //   name: "UserList",
  //   icon: "ni ni-circle-08 text-primary",
  //   component: UserList,
  //   layout: "/admin",
  //   role: ['admin'],

  // },
  // {
  //   path: "/failed",
  //   name: "Failed Transactions",
  //   icon: "ni ni-tv-2 text-primary",
  //   component: Failed,
  //   layout: "/admin"
  // },
  // {
  //   path: "/reversal",
  //   name: "Reversal",
  //   icon: "ni ni-lock-circle-open text-primary",
  //   component: Reversal,
  //   layout: "/admin"
  // },
  {
    path: '/change-password',
    name: 'Change Password',
    icon: 'ni ni-lock-circle-open text-primary',
    component: ChangePassword,
    layout: '/admin',
    role: ['admin', 'rep'],
  },
  // {
  //   path: "/dispute-resolution",
  //   name: "Dispute Resolutions",
  //   icon: "ni ni-single-02 text-yellow",
  //   component: DisputeResolutions,
  //   layout: "/admin"
  // },
  {
    path: '/login',
    component: Login,
    layout: '/auth',
    role: ['admin', 'rep'],
  },
  {
    path: '/reset_password',
    component: ForgotPassword,
    layout: '/auth',
    role: ['admin', 'rep'],
  },
];
export default routes;
